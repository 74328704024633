import { Registration } from 'destroyable';
import { forImmediate } from 'waitasecond';
import { ICommitData } from '../../../10-database/interfaces/ICommitData';
import { Authors } from '../../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { ToolbarName } from '../../../50-systems/ToolbarSystem/0-ToolbarSystem';
import { IToolbarIcon } from '../../../50-systems/ToolbarSystem/IToolbarIcon';
import { AbstractArt } from '../../../71-arts/20-AbstractArt';
import { consolex } from '../../../consolex';

/**
 * TODO: Probably split into two modules
 * TODO: Undo and redo should be written DRY
 */
internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/back-button-toolbar-icon',
        deprecatedNames: ['@collboard/back-button-toolbar-icon', 'BackButtonToolbarIcon'],
        title: { en: 'Undo and Redo button', cs: 'Tlačítko zpět a vpřed' },
        // Note: for basic modules omitting the description: { en: '', cs: '' },

        categories: ['Basic', 'Productivity'],
        icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/icons/undo.svg',
        screenshots: [
            /*TODO:*/
        ],
        author: Authors.hejny,
    },
    async setup(systems) {
        const { appState, toolbarSystem, materialArtVersioningSystem } = await systems.request(
            'appState',
            'toolbarSystem',
            'materialArtVersioningSystem',
        );
        return Registration.join(
            ...[
                {
                    order: 5,
                    section: -10,
                    icon: 'undo',
                    shortcut: ['Control', 'z'],
                    onClick: async () => {
                        for (let i = materialArtVersioningSystem.commitsPool.length - 1; i >= 0; i--) {
                            await forImmediate(/* Note: This is just async simulation of the situation that in the future there will be loading commits from past from server */);

                            // TODO: A bit wrong order of undoing

                            let last1Commit: ICommitData | null = materialArtVersioningSystem.commitsPool[i];
                            try {
                                if (!materialArtVersioningSystem.isLastInItsTree(last1Commit)) {
                                    throw new Error(`Undo:: Commit is not last in the tree.`);
                                }

                                if (last1Commit.operationName === 'UNDO') {
                                    last1Commit = materialArtVersioningSystem.findCommitById(last1Commit.previousId);
                                }

                                if (!last1Commit) {
                                    throw new Error(`Undo:: Pressed undo but there are no commits to undo.`);
                                }

                                if (!last1Commit.operationId) {
                                    throw new Error(`Undo:: Pressed undo but last commit has no operationId.`);
                                }

                                const last1Commits = materialArtVersioningSystem.findCommitsByOperationId(
                                    last1Commit.operationId,
                                );

                                const last2Commits = last1Commits.map((commit) => {
                                    const previousCommit = materialArtVersioningSystem.findPreviousCommit(commit);

                                    if (!previousCommit) {
                                        throw new Error(`Undo:: Pressed undo but last commit has nothing previous.`);
                                    }

                                    return previousCommit;
                                });

                                consolex.info(`Undoing ${last1Commit.operationName} (${last1Commit.operationId})`);
                                appState.cancelSelection();

                                return materialArtVersioningSystem
                                    .createOperation(`Undo`)
                                    .takeCommits(
                                        ...last2Commits.map(
                                            (commit) =>
                                                ({
                                                    treeId: commit.treeId,
                                                    version: commit.version - 1,
                                                    commitId: commit.previousId,
                                                } as ICommitData) /* <- TODO: Use satisfies instead of as */,
                                        ),
                                    )
                                    .update(
                                        ...last2Commits.map(
                                            (commit) => commit.art as AbstractArt,
                                        ) /* <- TODO: Use satisfies instead of as */,
                                    )

                                    .persist();
                            } catch (error) {
                                // TODO: In future type errors instead of this message prefix hack
                                // TODO: Or create const Error
                                if (error instanceof Error && /^Undo::/.test(error.message)) {
                                    continue;
                                } else {
                                    throw error;
                                }
                            }
                        }

                        // TODO: Disable the button in this case
                        consolex.warn(`Pressed undo but there are no commits to undo.`);
                        return undefined;
                    },
                },
                {
                    order: 6,
                    section: -10,
                    icon: 'redo',
                    shortcut: ['Control', 'y'],
                    onClick: async () => {
                        const notThisCommit = new Error();

                        for (let i = materialArtVersioningSystem.commitsPool.length - 1; i >= 0; i--) {
                            await forImmediate(/* Note: This is just async simulation of the situation that in the future there will be loading commits from past from server */);

                            const lastUntouchedUndoCommit: ICommitData = materialArtVersioningSystem.commitsPool[i];
                            try {
                                if (lastUntouchedUndoCommit.operationName !== 'UNDO') {
                                    throw notThisCommit;
                                }

                                if (materialArtVersioningSystem.hasNextCommits(lastUntouchedUndoCommit)) {
                                    throw notThisCommit;
                                }

                                const lastUntouchedUndoCommits = materialArtVersioningSystem.findCommitsByOperationId(
                                    lastUntouchedUndoCommit.operationId!,
                                );

                                return materialArtVersioningSystem
                                    .createOperation(`Redo`)
                                    .takeCommits(...lastUntouchedUndoCommits)
                                    .update(
                                        ...lastUntouchedUndoCommits.map((commit) => {
                                            // TODO: Some helper to go back and forward in the chain OR DRY with for

                                            let prev = materialArtVersioningSystem.findPreviousCommit(commit);
                                            if (!prev) {
                                                try {
                                                    prev = materialArtVersioningSystem.findOriginCommit(commit);
                                                } catch (error) {
                                                    throw notThisCommit;
                                                }
                                            }
                                            const prevNext = materialArtVersioningSystem.findNextCommit(prev);
                                            if (!prevNext) {
                                                throw notThisCommit;
                                            }
                                            const prevNextNext = materialArtVersioningSystem.findNextCommit(prevNext);
                                            if (!prevNextNext) {
                                                return prevNext.art as unknown as AbstractArt;
                                            } else {
                                                return prevNextNext.art as unknown as AbstractArt;
                                            }
                                        }),
                                    )
                                    .persist();
                            } catch (error) {
                                if (error === notThisCommit) {
                                    continue;
                                } else {
                                    throw error;
                                }
                            }
                        }

                        // TODO: Disable the button in this case
                        consolex.warn(`Pressed redo but there are no commits to redo.`);
                        return undefined;
                    },
                },
            ].map((icon) => toolbarSystem.getToolbar(ToolbarName.Tools).registerIcon(icon as IToolbarIcon)),
        );
    },
}));

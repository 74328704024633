import { Registration } from 'destroyable';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../../50-systems/ToolbarSystem/0-ToolbarSystem';
import { CurtainArt } from './CurtainArtModule';

internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: 'CurtainTool',
            title: { en: 'Curtain', cs: 'Opona' },
            description: {
                en: 'Hide part of your board behind the curtain',
                cs: 'Schovejte část své tabule za oponu',
            },
            keywords: ['opona', 'skrýš', 'test', 'tajenka'],
            categories: ['Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/icons/visibility.svg',
            author: Authors.rosecky,
        },
        toolbar: ToolbarName.Tools,
        icon: {
            name: 'curtain',

            order: 61,

            icon: 'visibility',
            boardCursor: 'not-allowed',
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, materialArtVersioningSystem } = await systems.request(
                    'touchController',
                    'collSpace',
                    'materialArtVersioningSystem',
                );

                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe(async (touch) => {
                        const boardPosition = collSpace.pickPoint(touch.firstFrame.position).point;

                        const newArt = new CurtainArt(boardPosition);

                        const operation = materialArtVersioningSystem.createPrimaryOperation();
                        operation.newArts(newArt);
                        operation.persist();
                    }),
                );
            },
        },
    }),
);

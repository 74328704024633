import { Color } from '../../../40-utils/color/Color';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeSkinModule } from '../../../50-systems/StyleSystem/makers/makeSkinModule';

internalModules.declareModule(() =>
    makeSkinModule({
        manifest: {
            name: 'DefaultBusinessSkin',
        },
        skin: {
            welcomeWallpaper: {
                // TODO: Put this 👇 file into the assets folder of the module
                backgroundImage: `url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/wallpapers/grid-light-wallpaper.png')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
            },
            // TODO: @roseckyj Nicer business skin
            borderRadius: 3,
            colors: {
                primary: Color.fromHex('#035E82'),
                light: Color.fromHex('#f2f2f2'),
                dark: Color.fromHex('#4e4e4e'),

                success: Color.fromHex('#3da948'),
                warning: Color.fromHex('#ffcc11'),
                error: Color.fromHex('#EA0000'),
                danger: Color.fromHex('#EA0000'),

                white: Color.fromString('white'),
                black: Color.fromString('black'),

                overlay: Color.fromString('black').withAlpha(0.2),
                // TODO: 🎨 Alpha means opacity or transparency?
            },
            darken: -0.05,
            lighten: 0.05,
            circles: true,
        },
    }),
);

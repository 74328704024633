import { Registration } from 'destroyable';
import React from 'react';
import { ConfirmIcon } from '../30-components/menu/ConfirmIcon';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { Translate } from '../50-systems/TranslationsSystem/components/Translate';
import { CornerstoneArt } from '../71-arts/30-CornerstoneArt';
internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/erase-tool',
            deprecatedNames: ['@collboard/erase-tool', 'EraseTool'],
            title: { en: 'Eraser', cs: 'Guma' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Art', 'Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/icons/erase.svg',

            author: Authors.collboard,
        },
        toolbar: ToolbarName.Tools,
        async icon(systems) {
            const { materialArtVersioningSystem } = await systems.request('materialArtVersioningSystem');
            return {
                name: 'erase' /* For triggering externally */,
                autoSelect: true,

                order: 30,

                icon: 'erase',
                boardCursor: 'crosshair',
                menu: (
                    <>
                        <ConfirmIcon
                            icon="board-clean"
                            title={
                                <Translate name="EraseTool / board-clean confirm ">
                                    Opravdu chcete smazat celou tabuli?
                                </Translate>
                            }
                            onConfirm={async () => {
                                materialArtVersioningSystem
                                    .createOperation('Clear whole board')
                                    .takeArts(
                                        ...materialArtVersioningSystem.artsPlaced.filter(
                                            // TODO: There should be prevented deleting of CornerstoneArt on server site
                                            (art) => !(art instanceof CornerstoneArt),
                                        ),
                                    )
                                    .delete();
                            }}
                        />
                    </>
                ),
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, materialArtVersioningSystem } = await systems.request(
                    'touchController',
                    'collSpace',
                    'materialArtVersioningSystem',
                );
                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe((touch) => {
                        const operation = materialArtVersioningSystem.createPrimaryOperation();

                        registerAdditionalSubscription(
                            touch.frames.subscribe(async (frame) => {
                                for (const art of materialArtVersioningSystem.artsPlaced) {
                                    if (art.isNear(collSpace.pickPoint(frame.position).point) && art.locked === false) {
                                        operation.takeArts(art).deleteArts();
                                    }
                                }
                            }),
                        );
                    }),
                );
            },
        },
    }),
);

import React from 'react';
import spaceTrim from 'spacetrim';
import { ScriptForServerRender } from '../../../30-components/utils/ScriptForServerRender';
import { Unwrap } from '../../../30-components/utils/Unwrap';
import { createTitle } from '../../../40-utils/createTitle';
import { jsxToHtml } from '../../../40-utils/jsx-html/jsxToHtml';
import { htmlToXmlValidHtml } from '../../../40-utils/jsx-html/makeXmlValid';
import { prettifyHtml } from '../../../40-utils/jsx-html/prettifyHtml';
import { addGlobalStyles } from '../../../40-utils/style/global/addGlobalStyles';
import { sortArtContainersByScreenPosition } from '../../../50-systems/ExportSystem/utils/sortArtContainersByScreenPosition';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { TranslateContext } from '../../../50-systems/TranslationsSystem/components/TranslateContext';

internalModules.declareModule(() => ({
    manifest: {
        name: '@collboard/internal/html-export',
        deprecatedNames: '@collboard/html-export',
        requirePermissions: ['view'],
    },
    async setup(systems) {
        const { exportSystem, translationsSystem, artSerializer } = await systems.request(
            'exportSystem',
            'translationsSystem',
            'artSerializer',
        );
        return exportSystem.registerFileSupport({
            priority: 10,
            mimeType: 'text/html',
            isHeavy: false /* <- TODO: [🐈] Maybe use to split between Simple and Complex HTML export */,
            async exportFile(options) {
                const {
                    artContainers,
                    boardname /* <- TODO: Use here framename (or exportname) not boardname WITHOUT to need to transform through createTitle */,
                    // boundingBox /* <- TODO: Use or remove*/,
                    // quality /* <- TODO: Use */,
                    // scale /* <- TODO: Use */,
                    // Note: isMaterialized is auto-applied by ExportSystem
                    // isLinked /* <- TODO: Use */,
                    // isTransparent /* <- TODO: Use */,
                    isTesting /* <- TODO: Use */,
                    isNative,
                    isHeavyExport,
                } = options;

                const jsx = (
                    <html
                        lang="cs"
                        dir="ltr"
                        {...(!isNative
                            ? {}
                            : {
                                  'xmlns:collboard':
                                      'https://xmlns.collboard.com/' /* <- TODO: [🐸] What is the best URL for this
                                                                           TODO: [🐸] Probbably use localhost:xxxx here and in deployment change to xmlns.collboard.com/26.3.4/...
                                                                           TODO: [🐸] Probbably include major.minor version to ensure immutability
                                                                           TODO: [🐸] add content to this page */,
                              })}
                    >
                        <head>
                            <meta charSet="UTF-8" />
                            {isNative && (
                                <meta
                                    {...{ 'collboard:import-with-module': '@collboard/internal/html-native-import' }}
                                />
                            )}

                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <title>
                                {createTitle({
                                    boardname,
                                    translationsSystem,
                                })}
                            </title>
                            {/* <meta name="description" content=" [🎏] Maybe also pass description here" /> */}
                            {`{{OPTIONS}}` /* <- [💅] */}
                            <link
                                // TODO: This should be in <style> tag
                                rel="stylesheet"
                                href="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/html-export-template/export-template.css"
                            />
                        </head>
                        <body>
                            {/* <- TODO: Have here option to view/edit link */}
                            <a href="https://collboard.com/" className="brand">
                                {/* [🌘] */}
                                {/* [🌘] Find all old logos (like logo-small), remove them from assets, replace them in code */}
                                <img src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/logo-small.png" alt="Collboard.com" />
                            </a>

                            {isTesting && <>Rendering {artContainers.length} arts </>}

                            <div id="board-bg"></div>
                            <div id="board-wrapper">
                                <main id="board" style={{ position: 'absolute', top: 0, left: 0 }}>
                                    <div className="cornerstone" data-collboard-art-id="cornerstone"></div>
                                    <TranslateContext.Provider value={translationsSystem}>
                                        {
                                            // TODO: [🐽] What is the best sorting for HTML export
                                            await sortArtContainersByScreenPosition(artContainers).mapAsync(
                                                async ({ art, element, isMaterial }, i) => {
                                                    return (
                                                        // TODO: Convert art into bitmap OR include styles
                                                        // TODO: [🧚] Maybe use <ArtShell/>

                                                        <div
                                                            // Note: [🍒][0]! This div is kind of <ArtOwnShell for the HTML export
                                                            data-collboard-art-id={art.artId}
                                                            key={art.artId}
                                                            style={{
                                                                ...{
                                                                    position: 'absolute',
                                                                    // TODO: Art should expose boundingbox to do this bellow with nicer syntax
                                                                    left: art.topLeft.x /* <- LIB xyzt .toTopLeft() */,
                                                                    top: art.topLeft.y /* <- LIB xyzt .toTopLeft() */,
                                                                    width:
                                                                        (art.bottomRight.x || 0) - (art.topLeft.x || 0),
                                                                    height:
                                                                        (art.bottomRight.x || 0) - (art.topLeft.x || 0),
                                                                },
                                                            }}
                                                            {
                                                                // TODO: This information is now useless - maybe remove
                                                                ...(isMaterial
                                                                    ? { 'collboard:art-materialization': 'material' }
                                                                    : { 'collboard:art-materialization': 'virtual' })
                                                            }
                                                            {...(!isNative || !isMaterial
                                                                ? {}
                                                                : {
                                                                      'collboard:art': JSON.stringify(
                                                                          await artSerializer.serialize(art),
                                                                      ),
                                                                  })}
                                                        >
                                                            {element ? (
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: element.innerHTML,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {/* Note: Here is unwrapped the arts div in future [🍒][0]! <ArtOwnShell*/}
                                                                    <Unwrap>{await art.render(false, systems)}</Unwrap>
                                                                </>
                                                            )}
                                                        </div>
                                                    );
                                                },
                                            )
                                        }
                                    </TranslateContext.Provider>
                                </main>
                            </div>
                            <script src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/html-export-template/export-template.js"></script>

                            <ScriptForServerRender>
                                {`
                                    addBoardInteractivity({
                                        artId: 'cornerstone' /* <- TODO: Use here scope of the export */,
                                        boardElement: document.querySelector('#board'),
                                        leadingElement: document.querySelector('#board-wrapper'),
                                        backgroundElement: document.querySelector('#board-bg'),
                                    });
                                `}
                            </ScriptForServerRender>
                        </body>
                    </html>
                );

                let html = await Promise.resolve(jsx).then(jsxToHtml).then(addGlobalStyles).then(htmlToXmlValidHtml);

                // TODO: Probbably do via some xpath or more structural way
                html = html.split('<img').join('<img draggable=false');

                // [💅]
                html = html.split('{{OPTIONS}}').join(
                    spaceTrim(
                        (block) => `
                            <!--
                                ${block(JSON.stringify({ ...options, artContainers: undefined }, null, 4))}
                            -->
                            `,
                    )
                        .split('\n')
                        .map((row) => ' '.repeat(8) + row)
                        .join('\n'),
                );

                if (isHeavyExport) {
                    html = prettifyHtml(html);
                }

                return html;

                /*
                Note: Old method just for backup>
                TODO: [🐈] Maybe use to split between Simple and Complex HTML export

                return sortArtContainersByScreenPosition(artContainers)
                    .map(({ element }) => element?.innerHTML || '')
                    .join('\n');


                /**
                        Note: This is hack how to workaround application/collboard type. Use it if this mimetype does not work in ClipboardItem when migrating from ClipboardEvent
                            +------------------------
                            | (
                            |     await (
                            |         artContainers.mapAsync(async (artContainer) => ({
                            |             ...artContainer,
                            |             serializedArt: await artSerializer.serialize(artContainer.art),
                            |         }))
                            |     )
                            | )
                            +------------------------
                            | .map(
                            |     ({ serializedArt, element }) =>
                            |         `${element.innerHTML}<!--collboardart:${JSON.stringify(serializedArt)
                            |             // Note: This replacement is when HtmlArt serialization contains <!--html-comment-->
                            |             .split('-->')
                            |             .join('--\\u003E')}-->`,
                            | )
                            +------------------------

                        */
            },
        });
    },
}));

/**
 * TODO: Export used wallpaper
 * TODO: [🐾] Optimize HTML native export
 * TODO: Strategy how to attach/inline sources - images, style, scripts
 * TODO: Use here boundingBox and come centering
 * TODO: [🎇] Full vs linked export
 * TODO: isViewLinkIncuded isEditLinkIncuded
 * TODO: [💅] Decide and choose which type of inserting comments into the export is better
 */

import { Registration } from 'destroyable';
import React from 'react';
import { Transform } from 'xyzt';
import { Separator } from '../30-components/menu/Separator';
import { string_color } from '../40-utils/typeAliases';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { PointArt } from '../71-arts/50-PointArt/50-PointArt';
import { touchFrameToArtFrame } from './FreehandTool';

internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/point-tool',
            deprecatedNames: '@collboard/point-tool',
            title: { en: 'Drawind of dots', cs: 'Kreslení teček' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Art'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/icons/circle.svg' /* <- [0] Better */,

            author: Authors.collboard,
        },
        toolbar: ToolbarName.Tools,
        async icon(systems) {
            const { attributesSystem } = await systems.request('attributesSystem');
            return {
                //icon: ({ attributesSystem }) => ({
                name: 'point' /* For triggering externally */,
                autoSelect: true,

                order: 10,

                icon: 'circle' /* <- [0] Better */,
                boardCursor: 'crosshair',
                menu: (
                    <>
                        {attributesSystem.inputRender('weight')}
                        <Separator />
                        {attributesSystem.inputRender('color')}
                    </>
                ),
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, appState, attributesSystem, materialArtVersioningSystem } =
                    await systems.request(
                        'touchController',
                        'collSpace',
                        'appState',
                        'attributesSystem',
                        'materialArtVersioningSystem',
                    );

                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe((touch) => {
                        appState.cancelSelection();

                        // TODO: [👘] Nicer syntax
                        const frame = touchFrameToArtFrame(touch.firstFrame);
                        frame.position = collSpace.pickPoint(frame.position).point;

                        materialArtVersioningSystem
                            .createPrimaryOperation()
                            .newArts(
                                new PointArt(
                                    {
                                        spotSize:
                                            (attributesSystem.getAttributeValue('weight').value as number) * 3 +
                                            5 /* <- DRY [🌞] */,
                                    },
                                    {
                                        color: attributesSystem.getAttributeValue('color').value as string_color,
                                    },
                                    Transform.translate(frame.position),
                                ),
                            )

                            .persist();
                    }),
                );
            },
        },
    }),
);

/**
 * TODO: Real size VS size of the selection box VS size with near distance for selecting, erasing
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 * TODO: UX What about press and drag - probably same behaviour as 💙💚💛💜
 */

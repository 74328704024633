import React from 'react';
import { IconColor } from '../../30-components/menu/IconColor';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';

// TODO: Generate these module automatically

const colors: { [key: string]: string } = {
    red: '#ff0000',
    orange: '#ffa500',
    yellow: '#ffff00',
    green: '#008000',
    blue: '#0000ff',
    indigo: '#4b0082',
    violet: '#ee82ee',
};

internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            name: 'RainbowColorAttribute',
            title: { en: 'Rainbow colors', cs: 'Barvy duhy' },
            description: {
                // TODO: En: 'Use similar colors as those on H-edu',
                // TODO: Cs: 'Používejte na svých tabulích stejné barvy, jako jsou na H-edu',
            },
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/modules/Rainbow/icon.svg' /* TODO: Generate automatically */,
            contributors: [Authors.hejny],
            categories: ['Colors'],
        },
        standard: false,
        attribute: {
            type: 'string',
            name: 'color',
        },
        inputRender(value: string, onChange: (value: string) => void) {
            return (
                <>
                    {Object.keys(colors).map((key) => (
                        <IconColor
                            {...{ key }}
                            color={colors[key]}
                            active={value === colors[key]}
                            onClick={() => onChange(colors[key])}
                        />
                    ))}
                </>
            );
        },
    }),
);

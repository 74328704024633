import React from 'react';
import { map } from 'rxjs/operators';
import { ModalFooter } from '../../../../30-components/modal/ModalFooter/ModalFooter';
import { ModalGroup } from '../../../../30-components/modal/ModalGroup/ModalGroup';
import { ModalScroll } from '../../../../30-components/modal/ModalScroll/ModalScroll';
import { AsyncButtonComponent } from '../../../../30-components/utils/AsyncButtonComponent';
import { DisplayOn } from '../../../../30-components/utils/DisplayOn';
import { ObservableContentComponent } from '../../../../30-components/utils/ObservableContentComponent';
import { Color } from '../../../../40-utils/color/Color';
import { toArray } from '../../../../40-utils/toArray';
import { BusinessName } from '../../../../50-systems/BusinessSystem/configuration/BusinessName';
import { internalModules } from '../../../../50-systems/ModuleStore/internalModules';
import { makeModalModule } from '../../../../50-systems/ModuleStore/makers/makeModalModule';
import { Translate } from '../../../../50-systems/TranslationsSystem/components/Translate';
import { consolex } from '../../../../consolex';
import { HandwrittenCollboardLogo } from '../HandwrittenCollboardLogo';
import { WelcomeModal } from './WelcomeModal';

for (const business of Object.keys(BusinessName)) {
    internalModules.declareModule(() =>
        makeModalModule({
            manifest: {
                name: `${business}Welcome`,
                requirePermissions: [],
            },
            async createModal(systems) {
                const { apiClient, boardSystem, routingSystem, translationsSystem, businessSystem, licenseSystem } =
                    await systems.request(
                        'apiClient',
                        'boardSystem',
                        'routingSystem',
                        'translationsSystem',
                        'businessSystem',
                        'licenseSystem',
                    );

                async function createEmptyBoard() {
                    // TODO: DRY
                    // TODO: Prevent multiple clicks
                    // TODO: When clicked to the out of the modal start drawing - do it via handwritten loading index
                    await boardSystem.createNewBoard({
                        logger: consolex,
                        isPersistent: true,
                        isNewBoardNavigated: true,
                    });
                }

                return {
                    wrapModalManually: true,
                    content: (
                        <WelcomeModal
                            {...{
                                className: 'welcomeModal',
                                title: (
                                    <HandwrittenCollboardLogo
                                        color={Color.fromHex('#ffffff') /* <- TODO: From skin */}
                                        height={40}
                                        weight={2.5}
                                        style={{
                                            marginBottom: -10,
                                        }}
                                        onClick={(() => {
                                            // Note: This is an easter egg how you can trigger handwritten animation immediately
                                            // TODO: More easter eggs like this
                                            // TODO: Make for this multiple-click logic some abstraction logic util

                                            let clickCount = 0;

                                            return () => {
                                                clickCount++;

                                                if (clickCount === 4) {
                                                    localStorage.removeItem('Collboard_Initial_lastAnimationDate');
                                                } else if (clickCount === 5) {
                                                    window.location.reload();
                                                    // TODO: Maybe same logic in @collboard/internal/collboard-logo
                                                    //     > routingSystem.navigateHome();
                                                }
                                            };
                                        })()}
                                    />
                                ),

                                onClickOverlay: createEmptyBoard,
                            }}
                        >
                            <ModalScroll>
                                <ModalGroup
                                    isTitleVisibleOnSmallScreen={false}
                                    title={
                                        <Translate name={`${business}Welcome / choose how to start`}>
                                            Vyberte si, jak chcete začít:
                                        </Translate>
                                    }
                                >
                                    <AsyncButtonComponent
                                        className="welcomeBigBlue"
                                        alt="Waiting for new board to be creared"
                                        style={{
                                            backgroundImage:
                                                "url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/welcome/welcome-blank@2x.png')",
                                        }}
                                        href={`/new?redirect=yes` /* TODO: [🐫] Some DRY helper to create new links */}
                                        onClick={async () => {
                                            await boardSystem.createNewBoard({
                                                logger: consolex,
                                                isPersistent: true,
                                                isNewBoardNavigated: true,
                                            });
                                        }}
                                    >
                                        {/* TODO: Probably empty board also as a template */}
                                        <Translate name={`${business}Welcome / empty board"`}>Prázdná tabule</Translate>
                                    </AsyncButtonComponent>
                                    {/* Note: [🦧] All places in code which are implementing templates */}
                                    <div className="welcomeWrapper">
                                        {(await businessSystem.businessConfiguration).welcomeTemplates.map(
                                            (template, index) => {
                                                const link = routingSystem.homeUrl;

                                                // TODO: [🐫] Some DRY helper to create new links
                                                link.pathname = '/new';
                                                link.searchParams.set('redirect', 'yes');
                                                link.searchParams.set(
                                                    'modulesOn',
                                                    toArray(template.modulesOn).join(','),
                                                );
                                                link.searchParams.set(
                                                    'modulesOff',
                                                    toArray(template.modulesOff).join(','),
                                                );

                                                return (
                                                    <AsyncButtonComponent
                                                        alt="Waiting for new board from template to be created"
                                                        key={index /* TODO: Put here some template id */}
                                                        className="welcomeTemplate"
                                                        style={{ backgroundImage: `url('${template.image}')` }}
                                                        href={link.href}
                                                        onClick={async () => {
                                                            await boardSystem.createNewBoard({
                                                                logger: consolex,
                                                                modulesOn: template.modulesOn,
                                                                modulesOff: template.modulesOff,
                                                                useTemplate: template.importAsMaterialize,
                                                                isPersistent: true,
                                                                isNewBoardNavigated: true,
                                                            });
                                                        }}
                                                    >
                                                        <div className="welcomeTemplateTitle">
                                                            {typeof template.title === 'string' ? (
                                                                <Translate name={`Templates / ${template.title}`}>
                                                                    {template.title}
                                                                </Translate>
                                                            ) : (
                                                                translationsSystem.pickMessage(template.title)
                                                            )}
                                                        </div>
                                                    </AsyncButtonComponent>
                                                );
                                            },
                                        )}

                                        {/* TODO: @roseckyj Here should be link to more - a whole store of templates */}
                                        {/*
                                          Note: Now we have 6 templates to fill the space
                                          <div className="welcomeTemplateMore">
                                              <div className="welcomeTemplateMoreTitle">
                                                  {
                                                      <Translate name="Templates / coming soon">
                                                          Další šablony připravujeme...
                                                      </Translate>
                                                  }
                                              </div>
                                          </div>
                                          */}
                                    </div>
                                </ModalGroup>

                                <ObservableContentComponent
                                    alt="List of my licenses"
                                    loader={
                                        /*TODO: @roseckyj There should be some default loader that would cover only its area not the whole modal,
                                          now I am using instead a loader just a empty space*/
                                        <></>
                                    }
                                    content={licenseSystem.licensesTokens.pipe(
                                        map((licensesTokens) => {
                                            if (licensesTokens.size === 0) {
                                                return (
                                                    <>
                                                        {/* Note: No licenses active, there is no need to disturb the user */}
                                                    </>
                                                );
                                            }

                                            return (
                                                <ModalGroup
                                                    isTitleVisibleOnSmallScreen={false}
                                                    title={
                                                        <Translate
                                                            name={`${business}Welcome / You have some active licenses`}
                                                        >
                                                            Máte aktivované placené licence:
                                                        </Translate>
                                                    }
                                                >
                                                    <AsyncButtonComponent
                                                        alt="Showing licenses modal"
                                                        className="button button-primary modal-button"
                                                        style={{ marginLeft: 0 }}
                                                        onClick={() =>
                                                            routingSystem.urlVariables.setValue({
                                                                moduleName: '@collboard/internal/licenses',
                                                            })
                                                        }
                                                    >
                                                        {/* TODO: Better design of this button */}
                                                        {/* TODO: Put here as variable number of licenses */}
                                                        <Translate name={`${business}Welcome / Show active licenses`}>
                                                            Zobrazit licence
                                                        </Translate>
                                                    </AsyncButtonComponent>

                                                    {/* TODO: Put here licensed templates */}
                                                </ModalGroup>
                                            );
                                        }),
                                    )}
                                />

                                <ObservableContentComponent
                                    alt="List of my boards"
                                    loader={
                                        /*TODO: @roseckyj There should be some default loader that would cover only its area not the whole modal,
                                          now I am using instead a loader just a empty space*/
                                        <></>
                                    }
                                    content={apiClient.getMyBoards().pipe(
                                        map((myBoards) => {
                                            if (myBoards.data.length === 0) {
                                                return (
                                                    <>{/* Maybe here should be some info that you have no boards */}</>
                                                );
                                            }

                                            return (
                                                <ModalGroup
                                                    isTitleVisibleOnSmallScreen={false}
                                                    title={
                                                        <Translate
                                                            name={`${business}Welcome / or pick one of your boards`}
                                                        >
                                                            Nebo pokračujte na nějaké z vašich posledních tabulí:
                                                        </Translate>
                                                    }
                                                >
                                                    {myBoards.data.map((boardInfo) => (
                                                        <a
                                                            href={routingSystem.urlVariables
                                                                .createLink({
                                                                    uriId: boardInfo.uriId,
                                                                })
                                                                .toString()}
                                                            onClick={async (event) => {
                                                                event.preventDefault();
                                                                await boardSystem.navigateBoard(boardInfo.uriId);
                                                            }}
                                                            key={boardInfo.uriId}
                                                            className="welcomeBoard"
                                                            style={{
                                                                /* TODO: @roseckyj This should me more zoomed in and maybe it should do zoom-in animation on hover */
                                                                backgroundImage: `url('/${
                                                                    // TODO: Better Screenshotting
                                                                    //'assets/welcome/random-board@2x'
                                                                    boardInfo.uriId /*TODO: Probably escape*/
                                                                }.png?size=thumbnail')`,
                                                            }}
                                                        >
                                                            <div className="welcomeBoardTitle">
                                                                {boardInfo.boardname}
                                                                <div className="welcomeBoardSubtitle">
                                                                    {translationsSystem.showDateAndTime(
                                                                        boardInfo.lastEdit,
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    ))}
                                                </ModalGroup>
                                            );
                                        }),
                                    )}
                                />

                                {/*
                                  Note: [🧙‍♂️] Keeping this for texting design
                                  '********************************'.split('').map((_, i) => (
                                      <div
                                          key={i/* TODO: Probably better key * /}
                                          className="welcomeBoard"
                                          style={{ backgroundImage: "url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/welcome/random-board@2x.png')" }}
                                      >
                                          <div className="welcomeBoardTitle">
                                              Název tabule
                                              <div className="welcomeBoardSubtitle">10.12.2020</div>
                                          </div>
                                      </div>
                                  ))*/}
                            </ModalScroll>
                            <ModalFooter isSequestered={true}>
                                <ul>
                                    <DisplayOn desktop tablet>
                                        <li>© 2020 — {new Date().getFullYear()} Collboard.com</li>
                                    </DisplayOn>
                                    <DisplayOn mobile>
                                        <li>© Collboard</li>
                                    </DisplayOn>
                                    <li className="left">
                                        <a
                                            href="/-/about/~/principles"
                                            onClick={(event) => {
                                                //  TODO: LIB everstorage should be able to create key,href,onClick pair
                                                event.preventDefault();
                                                routingSystem.urlVariables.setValue({
                                                    moduleName: '@collboard/internal/about',
                                                    modulePath: '/principles',
                                                });
                                            }}
                                        >
                                            {/* TODO: Dynamically title from article which is translated */}
                                            <Translate name={`${business}Welcome / about`} isNonBreakSpaced>
                                                O nás
                                            </Translate>
                                        </a>
                                    </li>
                                    {/*<li>
                                          <a href="/-/about/~/privacy">
                                              href="/-/CollboardTermsAndConditions"
                                              onClick={(event) => {
                                                  event.preventDefault();
                                                  systemsContainer.routingSystem.path.setValue({
                                                      module: 'CollboardTermsAndConditions',
                                                  });
                                              }}
                                          >
                                              {/* TODO: Dynamically title from article which is translated * /} Obchodní podmínky
                                          </a>
                                      </li>*/}
                                    <DisplayOn desktop tablet>
                                        <li>
                                            <a
                                                href="/-/about/~/contact"
                                                onClick={(event) => {
                                                    //  TODO: LIB everstorage should be able to create key,href,onClick pair
                                                    event.preventDefault();
                                                    routingSystem.urlVariables.setValue({
                                                        moduleName: '@collboard/internal/about',
                                                        modulePath: '/contact',
                                                    });
                                                }}
                                            >
                                                {/* TODO: Dynamically title from article which is translated */}
                                                <Translate name={`${business}Welcome / contact`} isNonBreakSpaced>
                                                    Kontakt
                                                </Translate>
                                            </a>
                                        </li>
                                    </DisplayOn>
                                </ul>
                            </ModalFooter>
                        </WelcomeModal>
                    ),
                };
            },
        }),
    );
}

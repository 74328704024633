import React from 'react';
import styled from 'styled-components';
import { Color } from '../../40-utils/color/Color';
import { ObjectUrl } from '../../40-utils/files/ObjectUrl';
import { useSkin } from '../../40-utils/react-hooks/useSkin';
import { ISkin } from '../../50-systems/StyleSystem/ISkin';
import { AsyncContentComponent } from './AsyncContentComponent';

interface IFileComponentProps {
    /**
     * Shown file
     */
    file: File | Blob;

    /**
     * Is currently loading
     */
    isLoading?: boolean;

    /**
     * Images can be shown in <img> tag but if you set this to true, they will be shown in <iframe> tag.
     * @default false
     */
    isIframeEnforced?: boolean;
}

/**
 * Preview component of any file
 *
 * @collboard-modules-sdk
 */
export function FileComponent({ file, isLoading, isIframeEnforced }: IFileComponentProps) {
    const name = file instanceof File ? file.name : 'untitled';

    return (
        <FileDiv {...useSkin()} style={{ opacity: isLoading ? 0.5 : 1 /* <- TODO: To skin */ }}>
            {name !== 'untitled' && <div className="file-name">{name}</div>}
            <AsyncContentComponent
                alt={`file preview for ${name}`}
                content={async () => {
                    // TODO: [LEAK] Destroy ObjectUrl when component unmounts - Probbably via AsyncContentComponent
                    const { src } = ObjectUrl.fromBlob(file);

                    if (!isIframeEnforced && /^image\//.test(file.type)) {
                        return (
                            <div className="file-preview-background">
                                <img className="file-preview" alt={name} {...{ src }} />
                            </div>
                        );
                    } else {
                        return <iframe className="file-preview wanted-iframe" title={name} {...{ src }} />;
                    }
                }}
            />
        </FileDiv>
    );
}

const FileDiv = styled.div<{ skin: ISkin }>`
    margin-top: 20px;

    .file-name {
        display: block;
        font-size: 15px;
        font-weight: bold;
        /* padding: 7px; */
        color: ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0.5).toString()};
    }

    .file-preview {
        display: block;
        border: 2px solid ${({ skin }) => Color.from(skin.colors.dark).withAlpha(0.5).toString()};
    }

    img.file-preview {
        max-width: 100%;
    }

    .file-preview-background {
        display: inline-block;
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/patterns/chessboard.png');
        backgroud-repeat: repeat;
    }

    iframe.file-preview {
        width: 100%;
        height: 65vh;
    }
`;

/**
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 * TODO: Move to external LIB
 */

import React from 'react';
import { StyleSystem } from '../../../../50-systems/StyleSystem/0-StyleSystem';
import { LanguageWrapperStyledDiv } from './LanguageWrapperStyledDiv';

export function LanguageSelectComponent({ styleSystem }: { styleSystem: StyleSystem }) {
    return (
        <styleSystem.WithSkin
            content={(skin) => (
                <>
                    <LanguageWrapperStyledDiv {...{ skin }}>
                        {/* [🌐] */}
                        {/* TODO: Do not hardcode en,cs but create list of languages on one place */}
                        {/* TODO: Order this language list according to navigator.language  @see https://github.com/hejny/Ukraine/blob/main/src/getUserLanguage.ts */}
                        <a href="?language=uk" key="ua">
                            <img
                                className="language"
                                src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/languages/ua.svg"
                                alt="Перейти на українську"
                                title="🇺🇦 Перейти на українську"
                            />
                        </a>
                        <a href="?language=en" key="us">
                            <img
                                className="language"
                                src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/languages/us.svg"
                                alt="Switch to English"
                                title="🇺🇸 Switch to English"
                            />
                        </a>
                        <a href="?language=en" key="uk">
                            <img
                                className="language"
                                src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/languages/uk.svg"
                                alt="Switch to English"
                                title="🇬🇧 Switch to English"
                            />
                        </a>
                        <a href="?language=cs" key="cs">
                            <img
                                className="language"
                                src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/languages/cs.svg"
                                alt="Přepnout do Češtiny"
                                title="🇨🇿 Přepnout do Češtiny"
                            />
                        </a>
                        <a href="?language=sk" key="sk">
                            <img
                                className="language"
                                src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/languages/sk.svg"
                                alt="Slovenčina"
                                title="🇸🇰 Slovenčina"
                            />
                        </a>
                        <a href="?language=pl" key="pl">
                            <img
                                className="language"
                                src="https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.35/languages/pl.svg"
                                alt="Polski"
                                title="🇵🇱 Polski"
                            />
                        </a>
                    </LanguageWrapperStyledDiv>
                </>
            )}
        />
    );
}

/**
 * TODO: Use useSkin() instead of passing as a prop
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
